import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  //   {
  //   data: {
  //     navbarData,
  //     footerData,
  //     markdownRemark: { frontmatter, html },
  //   },
  // }
  <Layout /*footerData={footerData} navbarData={navbarData}*/>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage

// export const pageQuery = graphql`
//   query NotFoundPage($id: String!) {
//     ...LayoutFragment
//     markdownRemark(id: { eq: $id }) {
//       id
//       html
//     }
//   }
// `
